@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

/* Customize the scrollbar for webkit-based browsers */
::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
  height: 5px; /* Set the height of the scrollbar */
}

::-moz-scrollbar-button:decrement,
::-moz-scrollbar-button:increment,
::-webkit-scrollbar-button:decrement,
::-webkit-scrollbar-button:increment {
  width: 0px;
}

/* Style the thumb (the part of the scrollbar that you can drag) */
::-webkit-scrollbar-thumb {
  background: rgb(252, 50, 67); /* Set the background color of the thumb */
  border-radius: 10px; /* Round the corners of the thumb */
}

.skeletonDark {
  cursor: progress;
  position: relative;
  overflow: hidden;
  background-color: rgb(24, 24, 24);
  color: transparent !important;
  box-shadow: none !important;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(46, 46, 46, 0) 0,
      rgba(46, 46, 46, 0.2) 20%,
      rgba(46, 46, 46, 0.5) 60%,
      rgba(46, 46, 46, 0)
    );
    animation: shimmer 1s infinite;
    content: '';
  }
}

@keyframes shimmer {
  50% {
    transform: translateX(100%);
  }
}

.fade-enter.fade-enter-active {
  opacity: 1;
  /*
  Duration has been increased by 4x from the original version for demo purposes.
  */
  transition: opacity 1000ms ease-in;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
